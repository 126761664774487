<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mt--4 mb--5">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0">{{ tt('compose_message') }}</h3>
                        </div>
                        <div class="col text-right">
                            <base-button size="sm" type="default" @click="create">{{ tt('send_message') }}</base-button>
                        </div>
                    </div>
                </div>
                <div class="card-body mt-5">
                    <div class="form-group row mb-0">
                        <label class="col-1 col-form-label">{{ tt('from') }}</label>
                        <label class="col-11 col-form-label">
                            {{authUser.first_name}}
                        </label>                        
                    </div>    
                    <hr class="mt-0 mb-1">
                    <div class="form-group row mb-0">                                        
                        <label class="col-1 col-form-label">{{ tt('to') }}</label>
                        <div class="col-11">
                             <el-select v-model="value"
                                multiple
                                filterable
                                remote
                                reserve-keyword
                                :placeholder="tt('choose_to')"
                                :remote-method="loadAll"
                                :loading="loading">
                                <el-option v-for="item in to"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>                            
                        </div>                                                
                    </div>
                    <hr class="mt-0 mb-1">
                    <div class="form-group row mb-0">
                        <div class="col-12">
                            <input type="text" class="text-left form-control border-0 input-sm ml--2" :placeholder="tt('subject')" :name="tt('subject')">                            
                        </div>
                    </div>
                    <hr class="mt-0 mb-1">
                    <div class="form-group row mb-0">
                        <div class="col-12">
                            <base-input>
                                <textarea class="form-control border-0 ml--2" id="exampleFormControlTextarea1" :placeholder="tt('message')" rows="8">
                                </textarea>
                            </base-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal :show.sync="form.show">
            <template slot="header">
                <h5 class="modal-title">{{form.title}}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('code') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('code')" :placeholder="tt('code')" v-model="compose.code" rules="required"></base-input>

                <label class="form-control-label">{{ tt('description') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('description')" :placeholder="tt('description')" v-model="compose.description" rules="required"></base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                <base-button type="primary">{{ tt('add') }}</base-button>
            </template>
        </modal>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    
    export default {        
        data() {
            return {     
                form: {
                    title: this.tt('add_source_type'),
                    show: false
                },      
                formRelation: {
                    show: false
                }, 
                table: {
                    data: [
                        {
                            id: 1,
                            code: "VALID", 
                            description: "VALID MANUFACTURE REFERENCES",
                        },
                        {
                            id: 2,
                            code: "RAW",
                            description: "RAW DATA - NEED FOR STANDARDIZATION",
                        },
                        {
                            id: 3,
                            code: "OBSOLETE",
                            description: "OBSOLETE MANUFACTURE REFERENCES OR HAS BEEN REPLACED",
                        },
                    ]
                }, 
                value:'',
                loading: false,
                to: [],       
                compose: {
                    
                }  
            }
        },
        methods: {
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.form.title = this.tt('add_source_type');
                this.form.show = true;
            },
            edit(id) {
                this.form.title = this.tt('edit_source_type');
                this.form.show = true;
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        this.alertDialog('success', this.tt('success_delete'));
                    } else {
                        this.alertDialog('error', this.tt('error_delete'));
                    }
                })
            },
            relation(id) {
                this.formRelation.show = true;
                this.compose.serviceGroups = [
                    {
                        id: 901001,
                        service_group: "VEHICLE & O/H EQUIPMENT PARTS"
                    }
                ]
            },
            querySearch(queryString, cb) {
                var links = this.links;
                var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                // call callback function to return suggestions
                cb(results);
            },
            createFilter(queryString) {
                return (link) => {
                  return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            loadAll() {
                console.log('test');
                this.to = [
                  { "value": "Administrator" },
                  { "value": "38812117" },
                  { "value": "38812120" },
                  { "value": "38813333" },
                  { "value  ": "38812211" },
                 ];
            },
            handleSelect(item) {
                console.log(item);
            }
        },
        computed: {
            ...mapState({
                authUser :state => state.auth.user
            })
        },
        mounted() {
          this.links = this.loadAll();
        }   
    };
</script>
<style></style>
